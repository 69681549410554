import React from 'react';
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBView } from "mdbreact";
import hiabt1 from './assets/about/hiabt1.jpg';
import hiabt2 from './assets/about/hiabt2.jpg';


export const About = () => (
    <div>
    
    <MDBCard
        className="my-5 px-5 mx-auto"
        style={{ fontWeight: 300, maxWidth: "90%" }}
      >
        <MDBCardBody style={{ paddingTop: 0 }}>
          <h2 className="h1-responsive font-weight-bold my-5 text-center">
          About Us
          </h2>
          <p className="dark-grey-text mx-auto">
          Halos insight is an agriculturally innovated firm that has the mandate to add value to agricultural production and input supply. Halos Insight was established in the year 2019 with the main aim of bridge the gap between the input needs of the farmers when required, where required and in what quantity required fully considering the pockets of our farmers. We also go as well to help our farmers market their respective products, making sure they are paid when due and what is due. We are also involved in the processing industry where we make sure farm produce are made into consumable forms which are consumer friendly and economically worthy. </p>
          <p className="dark-grey-text ">
          At Halos Insight, we are built to serve our consumers to the utmost satisfaction they so desire.
          </p>
          <MDBRow>
            <MDBCol lg="6" md="12">
              <div>
                <MDBView hover rounded className="z-depth-1-half mb-4">
                  <img
                    className="img-fluid"
                    src={hiabt1}
                    alt=""
                  />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <h3 className="font-weight-bold dark-grey-text mb-3 p-0">
                  <a href="#!">VISION</a>
                </h3>
                <p className="dark-grey-text">
                We aspire to consistently achieve reasonable growth and profitability, while we create and promote sound environment.
                </p>
              </div>
            </MDBCol>

            <MDBCol lg="6" md="12">
              <div>
                <MDBView hover rounded className="z-depth-1-half mb-4">
                  <img
                    className="img-fluid"
                    src={hiabt2}
                    alt=""
                  />
                  <a href="#!">
                    <MDBMask overlay="white-slight" className="waves-light" />
                  </a>
                </MDBView>
                <h3 className="font-weight-bold dark-grey-text mb-3 p-0">
                  <a href="#!">MISSION</a>
                </h3>
                <p className="dark-grey-text">
                We are committed to excellence in customer service, leadership in our communities and growth in value.
                <ul >
                  <li>Provide high-quality,affordable contract solution</li>
                  <li>Create and cultivate long-term relationship with clients</li>
                  <li>Responding immediately to the changing needs of our society</li>
                  <li>Achieving complete customer satisfaction</li>
                  <li>Improve our services continuously and achieve the highest standard of performance.</li>
                </ul> 
                </p>

                
              </div>

             
              </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </div >
)