import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'react-tabs/style/react-tabs.css';
/* {equipment.hsEid} */


export const Rental = () => {
  const [equipments, setEquipments] = useState([]);

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

   useEffect(() => {
      //fetch('https://jsonplaceholder.typicode.com/posts?_limit=10')
      fetch('https://portal.thehavennation.org/assets/jx/thapi_hlseq.php')
         .then((response) => response.json())
         .then((data) => {
            console.log(data);
            setEquipments(data);
         })
         .catch((err) => {
            console.log(err.message);
         });
   }, []);

  const listItems = equipments.map((equipment) =>

      width >= 1024 ? (
      
    <Grid item xs={3}>
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 250 }}
        image={'https://portal.thehavennation.org/assets/uploads/equipments/'+equipment.hsEquipmentPhoto} 
        title={equipment.hsEqCode}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
        {equipment.hsEquipmentNm}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {equipment.hsEqCode}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small">Share</Button>
        <Button size="small">Learn More</Button> */}
      </CardActions>
    </Card>
  </Grid>
    ) : (
      
    <Grid item xs={6}>
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height:  250 }}
        image={'https://portal.thehavennation.org/assets/uploads/equipments/'+equipment.hsEquipmentPhoto} 
        title={equipment.hsEqCode}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
        {equipment.hsEquipmentNm}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {equipment.hsEqCode}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small">Share</Button>
        <Button size="small">Learn More</Button> */}
      </CardActions>
    </Card>
  </Grid>
    )
 );
 

    return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
          {listItems}
  </Grid>
    </Box>
  );
}
