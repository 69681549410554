import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter,  MDBIcon } from "mdbreact";

export const FooterPage = () => (
    <MDBFooter color="black" className="font-small pt-4 mt-4">
      <MDBContainer  className="text-center text-md-left" color="black" >
        <MDBRow>
        <MDBCol md="4">
            <h5 className="title">Quick Contact</h5>
            <h6>Halos Insight Limited</h6>
            <p><MDBIcon icon="address-book" /> 21/23, Billings Way,<br></br>
            <MDBIcon icon="address-book" /> Oregun-Ikeja<br></br>
            <MDBIcon icon="address-book" /> Lagos.</p>
            <p><MDBIcon icon="phone" /> Tel: +234 (0) 818 458 8808/ EXT: 105<br></br>
            <MDBIcon icon="envelope" /> Email: info@halosinsight.com<br></br>
            <MDBIcon icon="registered" /> RC 1497154</p>
          </MDBCol>
          <MDBCol md="4">
            <h5 className="title">About Us</h5>
            <p>Halos insight  is an economical equilibrium providing both traditional and innovative products and services. 
              In 2018  Halos Insight was registered to bridge  the gap between the need and the needy. 
            </p>
          </MDBCol>
          <MDBCol md="4">
            <h5 className="title">Links</h5>
            <ul>
              <li className="list-unstyled">
                <a href="/about"><MDBIcon icon="info-circle" /> About Us</a>
              </li>
              {/* <li className="list-unstyled">
                <a href="/training"><MDBIcon icon="school" /> Training</a>
              </li> */}
              <li className="list-unstyled">
                <a href="/service"><MDBIcon icon="cogs" /> Services and Solutions</a>
              </li>
              <li className="list-unstyled">
                <a href="/contact"><MDBIcon icon="paper-plane" /> Contact Us</a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      
      <div className="footer-copyright text-center py-3">
        <MDBContainer  color="gray">
        <hr></hr>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.halosinsight.com"> Halos Insight</a>
        </MDBContainer>
      </div>
    </MDBFooter>
  )
