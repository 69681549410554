import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css'; 
import { Group } from './Group';
import { Home } from './Home';
import { Rental } from './Rental';
import { About } from './About';
import { Service } from './Service';
import { Training } from './Training';
import { Consultancies } from './Consultancies';
import Contact from './Contact';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { NavbarPage } from './components/NavbarPage';
import { Jumbotron } from './components/Jumbotron';
import {FooterPage} from './components/FooterPage';

class App extends Component {
  render() {
  return (
     <React.Fragment>
        <Router>
          <NavbarPage />
          <Jumbotron />
          <Layout>
            <Switch>
            <Route exact path="/" component={Group} />
            <Route exact path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/service" component={Service} />
            <Route path="/rental" component={Rental} />
            <Route path="/training" component={Training} />
            <Route path="/consultancies" component={Consultancies} />
            <Route path="/contact" component={Contact} />
            <Route component={NoMatch} />
            </Switch>
          </Layout>
          <FooterPage></FooterPage>
        </Router>
     </React.Fragment>
     
  );
    


  }
  
}

export default App;