import React from 'react'
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView } from "mdbreact";
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { HashLink as Link } from 'react-router-hash-link';
import 'react-tabs/style/react-tabs.css';
import hiserv1 from './assets/service/hiserv1.jpg';
import hiserv2 from './assets/service/hiserv2.jpg';
import hiserv3 from './assets/service/hiserv3.jpg';
import { FaLocationArrow } from 'react-icons/fa';
import Gallery from 'react-photo-gallery';


const photos = [
  {
    src: require('./assets/biz/hibiz1.jpg'),
    width: 3,
    height: 3,
    title: 'Distributors of Agro chemicals, Input supplies and Food processors',
    link: 'http://localhost:3000/home'
  },
  {
    src: require('./assets/biz/hibiz2.jpg'),
    width: 3,
    height: 3,
    title: 'IT Services & Computer Supplies'
  },
  {
    src: require('./assets/biz/hibiz3.jpg'),
    width: 3,
    height: 3,
    title: 'Real Estate, Civil & Electrical Contractors'
  },
  {
    src: require('./assets/biz/hibiz4.jpg'),
    width: 3,
    height: 3,
    title: 'Petroleum Services'
  },
  {
    src: require('./assets/biz/hibiz5.jpg'),
    width: 3,
    height: 3,
    title: 'Aviation Service & Logistics'
  },
  {
    src: require('./assets/biz/hibiz6.jpg'),
    width: 3,
    height: 3,
    title: 'Clinical Supplies'
  },
  {
    src: require('./assets/biz/hibiz7.jpg'),
    width: 3,
    height: 3,
    title: 'Import & Exports'
  },
  {
    src: require('./assets/biz/hibiz8.jpg'),
    width: 3,
    height: 3,
    title: 'Distributors of Agro chemicals, Input supplies and Food processors.'
  },
  {
    src: require('./assets/biz/hibiz9.jpg'),
    width: 3,
    height: 3,
    title: 'Distributors of Agro chemicals, Input supplies and Food processors.'
  }
]; 

export const Service = ({title, direction}) => (
    <div>
    <MDBCard className="my-5 px-5 pb-5">
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
        Our Services & Solutions
        </h2>
        
        <Gallery photos={photos} direction={direction}/>
        <p className="text-left w-responsive mx-auto mb-5">
        Here are a list of our services and solutions:
        </p>
        {/* <h3 className="h1-responsive font-weight-bold text-left my-5">
        Business Capacity
        </h3> */}
                  <ul>
                    <li>Distribution of agro chemicals, input supplies and food processors.</li>
                    <li>IT Services & Computer Supplies.</li>
                    <li>Real Estate, Civil & Electrical Contractors, Management and Repairs Services.</li>
                    <li>Petroleum Services</li>
                    <li>Aviation Services & Logistics</li>
                    <li>Clinical Supplies</li>
                    <li>Import & Export Services</li>
                    <li>Mining, Gold and Diamond Service</li>
                    <li>Capitalist & General Contractors </li>
                  </ul>
                  Halos Insight guarantees reliable and competent services.
        <MDBRow>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <img
                className="img-fluid"
                src={hiserv3}
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <Link
  to="/service#S1" activeClassName="selected" className="green-text" 
  scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
>
              <h6 className="font-weight-bold mb-3">
                <MDBIcon icon="location" className="pr-2" />
                <FaLocationArrow /> Logistics
              </h6></Link>
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>Delivery Focused</strong>
            </h3>
            <blockquote className="blockquote mb-0">
                <p>
                {' '}Halos Insight as an agriculturally oriented firm, is concerned with the addition of value to the lives of our farmers and also their customers. We execute operations which involves input supply to farms and farmer's distributors. We make sure for the proper delivery of services and products when due, where due and in the form which is friendly to accommodate farmers use. We are competent in our service delivery to our farmers since they make up the bases for nation's development and industrial growth in Nigeria.{' '}
                </p>
                <p>
                {' '}At Halos Insight, we also help farmers market their products, making sure they are paid what their products are worth when they sell. We strive to keep the movement of food and agricultural product on a steady flow to meet the four pillars of food security which are accessibility, availability, pocket friendly wise and nutritional enrichment. At Halos Insight, our farmers are our pride.{' '}
                </p>
                <footer className="blockquote-footer">
                    <cite title="Source Title">Halos Insight</cite>
                </footer>
            </blockquote>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
        <MDBRow>
          <MDBCol lg="7"> <Link
  to="/service#S2" activeClassName="selected"  className="pink-text" 
  scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
>
              <h6 className="font-weight-bold mb-3">
                <MDBIcon icon="bus" className="pr-2" /> Transport
              </h6>
            </Link>
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>Necessity of Proper Circulation!</strong>
            </h3>
            <blockquote className="blockquote mb-0">
                <p>
                {' '}Agricultural production in Nigeria has been limited to the place factor of marketing either making scarcity in some parts of the nation or creating room for underpricing and spoilage in some regions. At Halos Insight, we solve this problem by basically understand the necessity of proper circulation of agricultural products to where needed and when needed, we bridge the gap between our farmers and their consumers and provide adequately the necessary farm products needed and produced by our farmers. At Halos Insight, we carefully and delicately handle our farmer’s products with excellence, making our farmers products in top notch shape as it was harvested on their fields.{' '}
                </p>
                <p>
                {' '}Considering the magnanimity of the cost of agricultural production of the pockets of our farmers, we make sure our charges are cheap and pocket friendly to ensure that our farmers have a fighting chance to get proper pricing at any location they choose. We are dedicated to the comfort and profitability of our farmers after the stress of farming and we make sure their consumers get the best off farm products where ever they live.{' '}
                </p>
                <footer className="blockquote-footer">
                    <cite title="Source Title">Halos Insight</cite>
                </footer>
            </blockquote>
          </MDBCol>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <img
                className="img-fluid"
                src={hiserv2}
                alt=""
              /><Link
              to="/service#S3" activeClassName="selected"  className="pink-text" 
              scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
            >
                <MDBMask overlay="white-slight" />
              </Link>
            </MDBView>
          </MDBCol>
        </MDBRow>
        <hr className="my-5" />
        <MDBRow>
          <MDBCol lg="5">
            <MDBView className="rounded z-depth-2 mb-lg-0 mb-4" hover waves>
              <img
                className="img-fluid"
                src={hiserv1}
                alt=""
              />
              <a href="#!">
                <MDBMask overlay="white-slight" />
              </a>
            </MDBView>
          </MDBCol>
          <MDBCol lg="7">
            <a href="#!" className="indigo-text">
              <h6 className="font-weight-bold mb-3">
                <MDBIcon icon="leaf" className="pr-2" /> Input Supplies
              </h6>
            </a>
            <h3 className="font-weight-bold mb-3 p-0">
              <strong>Cutting Edge Distinction</strong>
            </h3>
            <blockquote className="blockquote mb-0">
                <p>
                {' '}At Halos Insight, we understand the necessity and importance of timely and optimum availability of input for our farmers and as such, not only do we just deliver on our own end, the necessary input required for a complete farming circle, but we also go out of our way to teach practically, the uses, important and economics of our input to our farmers and retail farm distributors. Halos Insight also maintains a premium standard in the quality of input distributed because we believe the best will fetch our famers the best and their best would make us the best at what we do.{' '}
                </p>
                <p>
                {' '}Apparently, Halos fulfills on her obligations to being the best and the best, only does her farmers get. The quality, from our agrochemicals to farming implements to our agro-services is one of a kind, giving us a cutting edge distinction among our class.{' '}
                </p>
                <footer className="blockquote-footer">
                    <cite title="Source Title">Halos Insight</cite>
                </footer>
            </blockquote>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
    </div>
)