import React from 'react';
//import { MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import { Link } from 'react-router-dom';
import {  Navbar, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '../assets/hi_logo.png';
const Styles = styled.div`
  .navbar {
    background-color: #fff;
  }
  .mylogo {
    width: 130px;
    height: auto;
  }
  /*a, .navbar-brand, .navbar-nav .nav-link {
    color: green;
    &:hover {
      color: orange;
    }
  }*/
  .nav-pills nav-fills{ background-color: #000; 
    color: white;
    !important;
  }
`;
/* state = { isOpen: false }

toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen })
 */
//export const NavbarPage = (_state,_toggleCollapse) =>  {

export const NavbarPage = () =>  (
  <Styles>
  <Navbar default collapseOnSelect expand="lg">
    <Navbar.Brand href="/"><img src={logo} className="mylogo" alt="Halos Insight" /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
       <Nav className="ml-auto"><Nav.Item>
          <Nav.Link>
            <Link to="/home" >Home</Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link to="/about">About Us</Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link to="/service">Services and Solutions</Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link to="/rental">Equipment Rental</Link>
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link>
            <Link to="/training">Training</Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link to="/consultancies">Consultancies</Link>
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link>
            <Link to="/contact">Contact Us</Link>
          </Nav.Link>
        </Nav.Item> </Nav>
         {/*  <MDBNav className="nav-pills nav-fill" color="success" >
            <MDBNavItem>
            <MDBNavLink  color="green" to="/" >Home</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
            <MDBNavLink  color="light-green" to="/about">About Us</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
            <MDBNavLink  color="light-green" to="/service">Services and Solutions</MDBNavLink>
            </MDBNavItem> */}
            {/* <MDBNavItem>
            <MDBNavLink  color="light-green" to="/training">Training</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
            <MDBNavLink  color="light-green" to="/consultancies">Consultancies</MDBNavLink>
            </MDBNavItem> */}
            {/* <MDBNavItem>
            <MDBNavLink  color="light-green" to="/contact">Contact Us</MDBNavLink>
            </MDBNavItem>
            </MDBNav> */}
    </Navbar.Collapse>
  </Navbar>
</Styles >
    )
    