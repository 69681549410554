import React, { Component } from 'react';
import {  MDBBtn, MDBInput, MDBBtnGroup } from "mdbreact";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Iframe from 'react-iframe';
import Card from 'react-bootstrap/Card';
import constrip from './assets/hiconstrip.jpg';

const API_ENDPOINT = 'https://notify.thehavennation.org/send/send_email';
const API_TOKEN = 'JJZ4HJOOIDG1FX6GRDH3P035AKLMWNCR';


class Contact extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    submitting: false,
    error: false,
    success: false
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true });
    var bodyFormData = new FormData();
    bodyFormData.append("name", this.state.name);
    bodyFormData.append("email", this.state.email);
    bodyFormData.append("subject", this.state.subject);
    bodyFormData.append("message", this.state.message);
  
    axios.post(API_ENDPOINT, bodyFormData, 
    { 
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Credentials':true,
        'Authorization':  `Bearer ${API_TOKEN}`
    } 
  })
      .then(response => {
        console.log(response);
        this.setState({ submitting: false, success: true });
      })
      .catch(error => {
        console.log(error);
        this.setState({ submitting: false, error: true });
      });
  }
  

render() {
    return (
    <div>
        <Card>
        <Card.Img variant="top" src={constrip} />
        <Card.Body>
            <Card.Title><h2>Contact Us</h2></Card.Title>
            <Card.Text>
            <p>Kindly, use the contact form below to leave a message</p>
            <Container>
                <Row> 
                  <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.3242976523957!2d3.3622261150938595!3d6.606563895222348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b924c78e9ac83%3A0xb9a1909339d21248!2s21%2C%2023%20Billings%20Way%2C%20Oregun%5C%2C%20Ikeja!5e0!3m2!1sen!2sng!4v1582172552752!5m2!1sen!2sng" width="100%" height="200" frameBorder="0" style={{border:0}} allowfullscreen="" />
                </Row>
        </Container> 
        <br></br>
        <hr></hr>
        <Container>
  {/* Stack the columns on mobile by making one full-width and the other half-width */}
  <Row>
    <Col xs={12} md={4}>
    <h3>Halos Insight Ltd.</h3>
    <p> 21/23, Billings Way, Oregun-Ikeja, Lagos.</p>
    <p>+2347089660076 / +2348184588808</p>
    <p>Email: info@halosinsight.com</p>
    <h5>Opening Times:</h5>
    <ul>
      <li>Monday to Saturday 9am – 5pm</li>
    <li>Public Holidays: 9am – 12noon</li>
    <li>Sanitation Days: 12noon – 5pm</li>
    </ul>
    </Col>
    <Col xs={12} md={8}>
    <form  id="contact-form" onSubmit={this.handleSubmit}>
    <div className="md-form">
                <MDBInput type="text" icon="user" label="Your Fullname" iconClass="grey-text" id="name"  name="name" value={this.state.name} onChange={this.handleChange}/>
  </div>
  <div className="md-form">
                <MDBInput icon="envelope" label="Your Email" iconClass="grey-text" type="text" id="email" name="email" required  value={this.state.email} onChange={this.handleChange}/>
    </div>
  <div className="md-form" >
  <select className="browser-default custom-select" id="subject" name="subject" required  value={this.state.subject} onChange={this.handleChange} >
          <option>Choose Your Subject</option>
          <option value="Enquiries">Enquiries</option>
          <option value="Feedback">Feedback</option>
          <option value="Complaint">Complaint</option>
        </select>
  </div>
    <div className="md-form">
    <MDBInput icon="pencil-alt" label="Your Message" iconClass="grey-text" type="textarea" id="message"  name="message"  rows="5" required value={this.state.message} onChange={this.handleChange}
    />
    </div>
    <div className="text-center">
    <MDBBtnGroup>
        <MDBBtn  color="red">Reset</MDBBtn>
        <MDBBtn type="submit"  color="green" disabled={this.state.submitting}>
            { this.state.submitting ? 'Sending...' : 'Send' }</MDBBtn>
    </MDBBtnGroup>
    { this.state.success && <p className="success-message">Your message was sent successfully!</p> }
        { this.state.error && <p className="error-message">An error occurred while sending your message. Please try again later.</p> }
    </div>
    </form>
    </Col>
    
  </Row>
  </Container>
    
            </Card.Text>
        </Card.Body>
        </Card>
       </div>
);
}


    }
export default Contact;