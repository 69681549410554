import React from 'react';
import { Jumbotron as Jumbo, Container } from 'react-bootstrap';
import styled from 'styled-components';
import boatImage from '../assets/boatImage.jpg';

const Styles = styled.div`
  .jumbo {
    background: url(${boatImage}) no-repeat fixed bottom;
    background-size: cover;
    color: #000000;
    height: 150px;
    position: relative;
    z-index: -2;
  }
  .overlay {
    background-color: #fff;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  
  h2, p {
    text-shadow: 1px 1px #dedede;
  }

  .con{
    margin-bottom: 70px;
  }
`;

export const Jumbotron = () => (
  <Styles>
    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
      <Container>
        <h2>Welcome to Halos Insight</h2>
        <p className="con"><em>- Creating an enabling environment across sectors.</em></p>
        <br></br>
      </Container>
    </Jumbo>
  </Styles>
)// JavaScript Document