import React from 'react';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { HashLink as Link } from 'react-router-hash-link';
import './App.css';
import 'react-tabs/style/react-tabs.css';
import { FaBus } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';
import { FaLeaf } from 'react-icons/fa';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//import Button from 'react-bootstrap/Button';
import hicard1 from './assets/hicard.jpg';
import hicard2 from './assets/hicard2.jpg';
import hicard3 from './assets/hicard3.jpg';





const photos = [
    {
    name: 'Photo 1',
    url: require('./assets/carousel/hib01.png')
    },
    {
    name: 'Photo 2',
    url: require('./assets/carousel/hib02.png')
    },
    {
    name: 'Photo 3',
    url: require('./assets/carousel/hib03.png')
    },
    {
    name: 'Photo 4',
    url: require('./assets/carousel/hib04.png')
    },
    {
    name: 'Photo 5',
    url: require('./assets/carousel/hib05.png')
    },
    {
    name: 'Photo 6',
    url: require('./assets/carousel/hib06.png')
    },
    {
    name: 'Photo 7',
    url: require('./assets/carousel/hib07.png')
    }
]


const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slideToShow: 1,
    arrows: true,
    slideToScroll: 1,
    className: "slides",
    autoplay: true,
    autoplaySpeed: 3000
}

export const Home = () => (
    <div>
    <Slider {...settings}>
        {photos.map((photo) => {
          return(
            <div className="App" style={{padding:24}}>
              <img width="100%" className="sliderrd" src={photo.url} alt="Halos Insight" />
            </div>
          )
        })}
    </Slider>
    <br></br>
    <hr></hr>
    
        <Row>
    <Col xs={12} md={4}>
    <Card>
        <Card.Img variant="top" src={hicard2} />
        <Card.Body>
            <Card.Title><FaLocationArrow /> Logistics</Card.Title>
            <Card.Text>
            We strive to keep the movement of food and agricultural product on a steady flow to meet the four pillars of food security which are accessibility, availability, pocket-friendly wise and nutritional enrichment.
            <br></br>
            <br></br>
            </Card.Text>
            <Link  smooth  variant="primary"  to="/service#S1" >Learn More</Link>
        </Card.Body>
        </Card>
    </Col>
    <Col xs={12} md={4}>
    <Card>
        <Card.Img variant="top" src={hicard1} />
        <Card.Body>
            <Card.Title><FaBus /> Transport</Card.Title>
            <Card.Text>
            Considering the magnanimity of the cost of agricultural production of the pockets of our farmers, we make sure our charges are cheap and pocket-friendly to ensure that our farmers have a fighting chance to get proper pricing at any location they choose. 
            </Card.Text>
            <Link  smooth  variant="primary"  to="/service#S2" >Learn More</Link>
        </Card.Body>
        </Card>
    </Col>
    <Col xs={12} md={4}>
    <Card>
        <Card.Img variant="top" src={hicard3} />
        <Card.Body>
            <Card.Title><FaLeaf /> Input Supplies</Card.Title>
            <Card.Text>
            The quality, from our agrochemicals to farming implements to our agro-services is one of a kind, giving us a cutting edge distinction among our class.
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </Card.Text>
            <Link  smooth  variant="primary"  to="/service#S3" >Learn More</Link>
        </Card.Body>
        </Card>
    </Col>
  </Row>
  
       
    </div>
)