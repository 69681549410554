import React from 'react';
import Gallery from 'react-photo-gallery';

const photos = [
    {
      src: require('./assets/biz/hibiz1.jpg'),
      width: 3,
      height: 3,
      title: 'Distributors of Agro chemicals, Input supplies and Food processors',
      link: 'http://localhost:3000/home'
    },
    {
      src: require('./assets/biz/hibiz2.jpg'),
      width: 3,
      height: 3,
      title: 'IT Services & Computer Supplies'
    },
    {
      src: require('./assets/biz/hibiz3.jpg'),
      width: 3,
      height: 3,
      title: 'Real Estate, Civil & Electrical Contractors'
    },
    {
      src: require('./assets/biz/hibiz4.jpg'),
      width: 3,
      height: 3,
      title: 'Petroleum Services'
    },
    {
      src: require('./assets/biz/hibiz5.jpg'),
      width: 3,
      height: 3,
      title: 'Aviation Service & Logistics'
    },
    {
      src: require('./assets/biz/hibiz6.jpg'),
      width: 3,
      height: 3,
      title: 'Clinical Supplies'
    },
    {
      src: require('./assets/biz/hibiz7.jpg'),
      width: 3,
      height: 3,
      title: 'Import & Exports'
    },
    {
      src: require('./assets/biz/hibiz8.jpg'),
      width: 3,
      height: 3,
      title: 'Distributors of Agro chemicals, Input supplies and Food processors.'
    },
    {
      src: require('./assets/biz/hibiz9.jpg'),
      width: 3,
      height: 3,
      title: 'Distributors of Agro chemicals, Input supplies and Food processors.'
    },
    {
      src: require('./assets/biz/hibiz10.jpg'),
      width: 3,
      height: 3,
      title: 'Events Media Coverage and Equipment Rental.',
      link: 'http://localhost:3000/rental'
    }
  ]; 

export const Group = ({title, direction}) => (
    <div>
        <h2>{title}</h2>
        <Gallery photos={photos} direction={direction}/>

{/* <Gallery photos={photos} />; */}
       
    </div>
)